const Footer = () => {
  return (
    <div
      id="footer"
      className="w-full p-4 text-base text-center bg-gray-300 border-t-[1px] border-brand-divide"
    >
      <div className="justify-evenly flex items-center max-w-5xl mx-auto">
        <div className="flex flex-col items-center justi-center">
          <h5>
            <span className="font-semibold text-yellow-800">Aagam Jain </span>
            &copy;{new Date().getFullYear()}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Footer;
