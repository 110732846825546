const NavLinks = [
  {
    title: "About me",
    url: "/#AboutMe",
  },

  {
    title: "Skills",
    url: "/#Skills",
  },
  {
    title: "Work",
    url: "/#Work",
  },
  {
    title: "Projects",
    url: "/#Projects",
  },
  {
    title: "Contact",
    url: "/#Contact",
  },
];

export { NavLinks };
