import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import { Loader } from "./Components";

const Home = React.lazy(() => import("./Views/Home"));
//const PageNotFound = React.lazy(() => import("./Views/404Error"));

function App() {
  return (
    <div className="transition duration-1000 ease-in-out opacity-100">
      <React.Suspense
        fallback={
          <div className="p-5 max-w-screen-2xl min-h-screen flex items-center dark:text-white justify-center ">
            <Loader />
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={(props) => <Home {...props} />} />
          <Redirect to="/" />
        </Switch>
      </React.Suspense>
    </div>
  );
}

export default App;
