import React, { useState } from "react";
import logo from "../../Assets/Images/logo.png";
import { NavLinks } from "./constant";
import { MenuIcon, CrossIcon } from "../../Assets/Icons";
import { ThemeToggleButton } from "../../Theme";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleNavBar = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleNavBarClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div className=" sticky top-0 z-40 bg-white dark:bg-gray-800 dark:text-white ">
        <div className=" container mx-auto sm:px-6 lg:px-28 md:space-y-8">
          <div className=" sticky w-full max-w-8xl mx-auto flex flex-none justify-between items-center px-4 md:px-0">
            <div className="cursor-pointer">
              <img src={logo} alt="logo" className="w-16 sm:w-20" />
            </div>
            <ThemeToggleButton />
            <div className="navigation flex items-end">
              <div className="hidden xl:block">
                <ul className="flex justify-between items-center">
                  {NavLinks.map((item, index) => (
                    <li
                      key={`NavLink_${index}`}
                      className="ml-10 cursor-pointer"
                    >
                      <a href={item.url}>
                        <p className="transition duration-500 ease-in-out test-sm font-medium hover:text-yellow-500 ">
                          {item.title}
                        </p>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="xl:hidden block">
                <div aria-label="mobile-navigation">
                  <div className="flex items-end">
                    <button
                      type="button"
                      onClick={handleNavBar}
                      className="hover:text-yellow-500 transition duration-500 ease-in-out"
                    >
                      {isOpen ? <CrossIcon /> : <MenuIcon />}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {isOpen && (
            <div className="xl:hidden block shadow-lg border-white rounded-lg  ">
              <div className=" w-full px-4 bg-white dark:bg-gray-900 ">
                <div>
                  <ul className="flex flex-col">
                    {NavLinks.map((item, index) => (
                      <li
                        key={`NavLink_${index}`}
                        className="border-b py-4 cursor-pointer text-right"
                      >
                        <a href={item.url}>
                          <p
                            onClick={handleNavBarClose}
                            className="text-sm hover:text-yellow-500  "
                          >
                            {item.title}
                          </p>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NavBar;
