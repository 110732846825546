import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Background } from "./Theme";
import { ThemeProvider } from "./Theme/themeContext";

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider>
      <Background>
        <App />
      </Background>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
